body
{
	margin: 0;
	padding: 0;
}

.location-display
{
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: space-around;
	        justify-content: space-around;
	-webkit-align-items: center;
	        align-items: center;
	height: 100vh;
	background-size: cover;
	color: white;
	text-shadow: 0 0 10px black;
}

h1
{
	font-size: 26px;
}

@media screen and (min-width: 450px)
{
	h1
	{
		font-size: 35px;
	}
}

@media screen and (min-width: 600px)
{
	h1
	{
		font-size: 50px;
	}
}

@media screen and (min-width: 1000px)
{
	h1
	{
		font-size: 70px;
	}
}
.day-card
{
	background-color: skyblue;
	border-radius: 6px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	margin: 4px;
	padding: 4px;
}

.day-card span
{
	opacity: 0.6;
}

.forecast-wrapper
{
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
}

.forecast
{
	display: -webkit-flex;
	display: flex;
	width: auto;
	max-width: 100%;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	font-family: 'Lato', sans-serif;
}
