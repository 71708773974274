body
{
	margin: 0;
	padding: 0;
}

.location-display
{
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	height: 100vh;
	background-size: cover;
	color: white;
	text-shadow: 0 0 10px black;
}

h1
{
	font-size: 26px;
}

@media screen and (min-width: 450px)
{
	h1
	{
		font-size: 35px;
	}
}

@media screen and (min-width: 600px)
{
	h1
	{
		font-size: 50px;
	}
}

@media screen and (min-width: 1000px)
{
	h1
	{
		font-size: 70px;
	}
}