.day-card
{
	background-color: skyblue;
	border-radius: 6px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 4px;
	padding: 4px;
}

.day-card span
{
	opacity: 0.6;
}
