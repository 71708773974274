.forecast-wrapper
{
	display: flex;
	justify-content: center;
}

.forecast
{
	display: flex;
	width: auto;
	max-width: 100%;
	justify-content: center;
	flex-wrap: wrap;
	font-family: 'Lato', sans-serif;
}